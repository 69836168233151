@import ~@vlabs/uikit/styles/theme
  
.GoToCellWrapper
  display: flex
  align-items: center

.Icon
  height: 20px
  width: 20px
  color: $color-black-60
  transition: all 0.3s
  cursor: pointer

  &:hover
    color: $color-primary-100
