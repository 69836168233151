@import ~@vlabs/uikit/styles/theme

.ResultCellWrapper
	display: flex
	align-items: center


.Icon
	transition: all 0.3s
	color: $color-black-60
	text-decoration: none
	height: 20px

	&:active, &:visited
		color: $color-primary-100

	&:hover, &:focus
		color: $color-primary-100
		cursor: pointer
