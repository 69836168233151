@import ~@vlabs/uikit/index

body
  margin: 0
  font-family: Montserrat, serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-size: 14px

html,
body
  min-height: 100vh

*
  scrollbar-width: thin

*::-webkit-scrollbar
  width: 8px
  height: 10px

*::-webkit-scrollbar-thumb
  border-radius: 0px
  border: 4px solid hsla(0, 0%, 100%, 0)
  background-color: hsla(0, 0%, 63%, 0.3)

@import ~@vlabs/uikit/styles/typography
