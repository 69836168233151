@import ~@vlabs/uikit/styles/theme
@import ~@vlabs/uikit/styles/typography

.App__AccountMenu
  margin: -14px

.App__Link
  @extend .Body-2
  font-family: Montserrat, serif
  box-sizing: border-box
  transition: all .3s
  display: flex
  align-items: center
  padding: 7px 10px
  width: 100%
  color: $color-black-100
  text-decoration: none
  position: relative

  &:hover, &:focus
    outline: 0
    background: rgba(0,126,255,.05)
    color: $color-primary-100

    &:first-child
      padding-top: 11px

    &:last-child
      padding-bottom: 11px

.App__Link_btn
  background-color: transparent
  border: 0
  cursor: pointer

.App__Icon
  margin-right: 8px
  height: 18px

.AccountMenu__visible
  z-index: 2

.App__Sticky
  position: sticky
  top: 0
  z-index: 2

.Table__ScrollContainer
  overflow-x: initial !important
