@import ~@vlabs/uikit/styles/theme

.ProgressCell
    text-align: center
    width: 100%
    white-space: nowrap

.ProgressCell__Label

    &_pending
        color: $color-warning-default

    &_finished
        color: $color-success-default

    &_canceled
        color: $color-error-default
        transform: rotate(-45deg)

    &_interrupted
        color: $color-error-default

    &_neutral
        color: $color-black-60
