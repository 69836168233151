@import ~@vlabs/uikit/styles/theme

.Icon
  cursor: pointer
  vertical-align: middle
  stroke: $color-black-60
  transition: all 0.3s

  &:hover, &:focus
    stroke: $color-primary-100
