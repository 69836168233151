@import ~@vlabs/uikit/styles/theme

.Toastify__toast-body
  max-width: 270px
  word-wrap: break-word

.Toastify__toast
  background: $color-black-5
  border: 1px solid $color-black-20
  border-radius: 8px
  box-shadow: 0px 10px 10px rgba(37, 40, 43, 0.15)
  font-family: Montserrat
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.1px
  color: $color-black-80

  .Toastify__progress-bar
    background-color: $color-black-20

  &--success
    color: $color-success-darker
    border-color: $color-success-default
    background: $color-white rgba($color-success-default, 0.04)

    .Toastify__progress-bar
      background-color: $color-success-default

  &--warning
    color: $color-warning-darker
    border-color: $color-warning-default
    background: $color-white rgba($color-warning-default, 0.04)

    .Toastify__progress-bar
      background-color: $color-warning-default

  &--error
    color: $color-error-darker
    border-color: $color-error-default
    background: $color-white rgba($color-error-default, 0.04)

    .Toastify__progress-bar
      background-color: $color-error-default

.Toastify__close-button
  color: $color-black-60

.Toastify__toast-body>*
  width: 100%
