@import ~@vlabs/uikit/styles/zindex
@import ~@vlabs/uikit/styles/theme

.CloseButton
  position: absolute
  right: 0
  top: 0
  z-index: $zModal
  height: 20px
  width: 20px
  color: $color-black-60
  transition: all 0.3s
  cursor: pointer
  padding: .5em

  &:hover
    color: $color-black-80
  &:active
    color: $color-black-100
