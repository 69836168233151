.Header
	display: flex
	justify-content: flex-end

.Inline
	display: inline-block

.Button
	display: inline-block
	margin-left: 10px
