@import ~@vlabs/uikit/styles/theme
@import ~@vlabs/uikit/styles/typography

.Panel
  padding: 14px

.Text
  ul
    padding-left: 1em

.LicenseInfo
  div
    display: flex
    justify-content: space-between
    margin-top: .5em
    &:first-child
      margin-top: 0

    dt
      font-weight: bold
    dd
      margin-left: 1em

.Footer
  display: flex
  justify-content: flex-end
